import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { connect } from "react-redux"
// import loadable from "@loadable/component"
import ProductPage from "../components/Product/ProductPage"
import { setLocation } from "../actions/setLocation"
import SuspenseLoading from "../components/SuspenseLoading"
import { productPageReset } from "../actions/setProductPage"

// const ProductPage = loadable(
//   () => import(/* webpackPrefetch: true */ "../components/Product/ProductPage"),
//   { fallback: <SuspenseLoading /> }
// )

const Products = ({ dispatch }) => {
  //Set the location.
  useEffect(() => {
    let mounted = true

    if (mounted) {
      dispatch(setLocation("/shop"))
    }

    //Clean up to prevent lingering states.
    return () => {
      mounted = false
      dispatch(productPageReset())
    }
  }, [])

  return (
    <Router basepath="/products">
      <ProductPage path=":productHandle" />
    </Router>
  )
}

export default connect()(Products)
