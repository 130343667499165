export const setMacarons = macarons => ({
  type: "SET_MACARONS_UNFILTERED",
  macarons,
})

export const addMacaron = macaron => ({
  type: "ADD_MACARON",
  macaron,
})

export const removeMacaron = macaron => ({
  type: "REMOVE_MACARON",
  macaron,
})

export const removeAllSelectedMacarons = () => ({
  type: "REMOVE_ALL_SELECTED_MACARONS",
})

export const setPreviousSelectedMacarons = () => ({
  type: "SET_PREVIOUS_MACARON_SELECTION_STATE",
})

export const cancelledAndResetSelection = () => ({
  type: "CANCELLED_AND_RESET_SELECTION",
})

export const setBoxCapacity = cap => ({
  type: "SET_BOX_CAPACITY",
  cap,
})

export const toggleNotCompleteWarning = toggle => ({
  type: "NOT_COMPLETE_MACARONS_WARNING",
  toggle,
})
