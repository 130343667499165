import styled from "styled-components"

import breakPoints from "../../APIs/devices"

export const ToggleBar = styled.div`
  display: flex;
  border: 1px solid var(--grey);
  border-radius: 50px;
  padding: 5px;
  margin: 0 auto 2rem auto;
  width: 100%;
`

export const ToggleItem = styled.div`
  flex-grow: 1;
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  font-family: Sweet Sans Pro;
  color: var(--ruelo-dark-purple);
  border-radius: 30px;
  padding: 10px 0 10px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    padding: 20px 0 20px;
  }
  &:nth-child(n) {
    margin-right: 10px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    cursor: pointer;
  }
  ${({ $active }) => {
    if ($active) {
      return `
          background-color: var(--ruelo-dark-purple);
        color: white;
          `
    }
  }}
`

export const ToggleItemActive = styled(ToggleItem)`
  background-color: var(--ruelo-dark-purple);
  color: white;
`

export const ToggleItemDisabled = styled(ToggleItem)`
  color: var(--grey);
  background-color: white;
  pointer-events: none;
`
