import { convertToTimeZone } from "date-fns-timezone"
import format from "date-fns/format"
import addDays from "date-fns/addDays"

import { store } from "../lib/reduxStore"

export default function determineEarliestDay(restriction) {
  const { days, daysAvailable } = restriction
  const {
    stores: {
      restrictions: { endOfDay },
    },
  } = store.getState()
  const today = convertToTimeZone(new Date(), { timeZone: "America/Toronto" })
  const dayOfWeek = format(today, "EEE") //Mon, Tue, Wed...
  const hourNow = format(today, "H") //0 - 23

  let dayEnded = false

  //Check if there are end of day cutoffs.
  if (endOfDay?.[dayOfWeek]) {
    const { hour } = endOfDay[dayOfWeek]

    if (hourNow >= hour && hour !== 0) {
      dayEnded = true
    }
  }

  let earliest = addDays(today, Number(days))

  if (dayEnded) {
    earliest = addDays(earliest, 1)
  }

  if (!daysAvailable) {
    return earliest
  }

  const values = Object.values(daysAvailable)

  if (values.length === 0) {
    return earliest
  } else {
    if (!values.includes(true)) {
      return null
    }

    //See which days are available
    const filterDays = Object.keys(daysAvailable).filter(
      key => daysAvailable[key]
    )

    while (!filterDays.includes(format(earliest, "eee"))) {
      //Format for days is Mo, Tu, We...
      earliest = addDays(earliest, 1)
    }
  }

  return earliest
}
