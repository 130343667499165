import React from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { FaPlus, FaMinus } from "react-icons/fa"

import { FlexItem } from "../../FlexGrid/FlexGrid"
import Macaron from "./Macaron"
import { addMacaron, removeMacaron } from "../../../actions/macaronPageActions"
import breakPoints from "../../../APIs/devices"

const MacaronsSlideContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  overflow-y: scroll;
  flex: 1 1 auto;
  @media only screen and (min-width: ${breakPoints.md}) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`

const MacaronsContainer = styled.div`
  position: relative;
  padding-top: 125%;
  width: 100%;
`

const MacaronsWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25%;
  transition: transform 250ms;
  z-index: 1;
  /* background: var(--grey); */
  border: 2px solid
    ${({ selected }) =>
      selected ? "var(--ruelo-light-purple)" : "var(--grey)"};
  border-radius: 10px;
`

const CountDisplay = styled(FlexItem)`
  padding-bottom: 4px;
  position: absolute;
  font-size: 1.2rem;
  top: -2px;
  left: 0;
  width: 100%;
  height: 25px;
  color: var(--black);
  text-align: center;
  font-family: Sweet Sans Pro;
  background-color: ${({ selected }) =>
    selected ? "var(--ruelo-light-purple)" : "darkgray"};
  border-radius: 10px;
  z-index: 1;
  @media only screen and (min-width: 375px) {
    height: 30px;
  }
`

const RemoveButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -2px;
  left: -2px;
  width: 32px;
  height: 32px;
  color: ${({ removeable }) => (removeable ? `white` : "darkgray")};
  text-align: center;
  font-family: Sweet Sans Pro;
  background-color: gray;
  border-radius: 10px 0 10px 0;
  z-index: 1;
  &:hover {
    cursor: pointer;
    color: darkgray;
  }
  &:active {
    transform: ${({ removeable }) => removeable && `scale(0.97)`};
  }
  @media only screen and (min-width: 375px) {
    width: 40px;
    height: 40px;
  }
`

const PlusButton = styled(FlexItem)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -2px;
  right: -2px;
  width: 32px;
  height: 32px;
  color: white;
  text-align: center;
  font-family: Sweet Sans Pro;
  background-color: gray;
  border-radius: 0 10px 0 10px;
  z-index: 1;
  &:hover {
    cursor: pointer;
    color: darkgrey;
  }
  &:active {
    transform: scale(0.97);
  }
  @media only screen and (min-width: 375px) {
    width: 40px;
    height: 40px;
  }
`

const Minus = styled(FaMinus)`
  width: 50%;
  height: 50%;
`

const Plus = styled(FaPlus)`
  width: 50%;
  height: 50%;
`

const OnClickWrapper = styled.div`
  margin: 0 auto;
  width: 60%;
  height: 60%;
  &:active {
    transform: scale(0.97);
  }
`

const MacaronTitle = styled.p`
  text-align: center;
  font-family: Sweet Sans Pro;
  width: 100%;
  margin-bottom: 0;
  /* font-size: 2rem; */
  font-size: 0.85rem;
  @media only screen and (min-width: 460px) {
    font-size: 1rem;
  }
`

const MacaronSlide = () => {
  //Redux
  const dispatch = useDispatch()
  const {
    macaronsUnfiltered: macarons,
    boxCap,
    selection,
    selectedMacarons,
  } = useSelector(state => state.macaronPage)

  function handleAddMacaron(macaron) {
    const difference = boxCap - selection.length
    if (difference > 0) {
      dispatch(addMacaron(macaron))
    }
  }

  return (
    <MacaronsSlideContainer>
      {macarons.map((macaron, index) => (
        <MacaronsContainer key={index}>
          <MacaronsWrapper selected={selectedMacarons[macaron.node.handle]}>
            <CountDisplay selected={selectedMacarons[macaron.node.handle]}>
              {selectedMacarons[macaron.node.handle] &&
                selectedMacarons[macaron.node.handle].amount}
            </CountDisplay>
            <PlusButton onClick={() => handleAddMacaron(macaron)}>
              <Plus />
            </PlusButton>
            <RemoveButton
              removeable={selectedMacarons[macaron.node.handle]}
              onClick={() =>
                selectedMacarons[macaron.node.handle] &&
                dispatch(removeMacaron(macaron))
              }
            >
              <Minus />
            </RemoveButton>
            <OnClickWrapper onClick={() => handleAddMacaron(macaron)}>
              <Macaron
                metafields={macaron.node.metafields}
                width="100%"
                height="100%"
              />
            </OnClickWrapper>
            <MacaronTitle>{macaron.node.title.toLowerCase()}</MacaronTitle>
          </MacaronsWrapper>
        </MacaronsContainer>
      ))}
    </MacaronsSlideContainer>
  )
}

export default React.memo(MacaronSlide)
