import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import breakPoints from "../../../APIs/devices"

const Container = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const SVG = styled.svg`
  width: ${({ width }) => (width === "" ? "88px" : width)};
  height: ${({ height }) => (height === "" ? "71.2px" : height)};
  @media only screen and (min-width: ${breakPoints.md}) {
    width: ${({ width }) => (width === "" ? "99px" : width)};
    height: ${({ height }) => (height === "" ? "80.1px" : height)};
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    width: ${({ width }) => (width === "" ? "110px" : width)};
    height: ${({ height }) => (height === "" ? "89px" : height)};
  }
`

const MacaronEmpty = ({ width: newWidth, height: newHeight, color }) => {
  return (
    <Container>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={newWidth ? `${newWidth}` : ``}
        height={newHeight ? `${newHeight}` : ``}
        viewBox="0 0 110 89"
      >
        <g
          id="Group_129"
          data-name="Group 129"
          transform="translate(1956 -13293)"
        >
          <g
            id="Rectangle_130"
            data-name="Rectangle 130"
            transform="translate(-1956 13293)"
            fill="none"
            stroke={color ? color : "#e6e6e6"}
            strokeWidth="3"
          >
            <path
              d="M28.333,0H81.667A28.333,28.333,0,0,1,110,28.333v0A5.667,5.667,0,0,1,104.333,34H5.667A5.667,5.667,0,0,1,0,28.333v0A28.333,28.333,0,0,1,28.333,0Z"
              stroke="none"
            />
            <path
              d="M27.877,1.5H82.123A26.377,26.377,0,0,1,108.5,27.877v0a4.623,4.623,0,0,1-4.623,4.623H6.123A4.623,4.623,0,0,1,1.5,27.877v0A26.377,26.377,0,0,1,27.877,1.5Z"
              fill="none"
            />
          </g>
          <g
            id="Rectangle_133"
            data-name="Rectangle 133"
            transform="translate(-1956 13349)"
            fill="none"
            stroke={color ? color : "#e6e6e6"}
            strokeWidth="3"
          >
            <path
              d="M5.5,0h99A5.5,5.5,0,0,1,110,5.5v0A27.5,27.5,0,0,1,82.5,33h-55A27.5,27.5,0,0,1,0,5.5v0A5.5,5.5,0,0,1,5.5,0Z"
              stroke="none"
            />
            <path
              d="M5.974,1.5h98.053A4.474,4.474,0,0,1,108.5,5.974v0A25.526,25.526,0,0,1,82.974,31.5H27.026A25.526,25.526,0,0,1,1.5,5.974v0A4.474,4.474,0,0,1,5.974,1.5Z"
              fill="none"
            />
          </g>
          <g
            id="Rectangle_132"
            data-name="Rectangle 132"
            transform="translate(-1956 13330)"
            fill="none"
            stroke={color ? color : "#e6e6e6"}
            strokeWidth="3"
          >
            <rect width="110" height="16" rx="8" stroke="none" />
            <rect
              x="1.5"
              y="1.5"
              width="107"
              height="13"
              rx="6.5"
              fill="none"
            />
          </g>
        </g>
      </SVG>
    </Container>
  )
}

MacaronEmpty.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default React.memo(MacaronEmpty)
