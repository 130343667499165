import React, { useState } from "react"
import { connect } from "react-redux"

import MacaronsSelection from "./MacaronsSelection"
import VariantsSelection from "../GeneralSection/VariantsSelection"

const MacaronsBoxSection = ({ hasVariants, product }) => {
  const [toggle, setToggle] = useState(
    hasVariants ? `${product.options[0].name}` : "Options"
  )

  return (
    <div>
      <MacaronsSelection />
      {hasVariants && <VariantsSelection />}
    </div>
  )
}

const mapStateToProps = ({ productPage: { currentProduct } }) => {
  const hasVariants = currentProduct.variants.edges.length > 1 ? true : false

  return { hasVariants, product: currentProduct }
}

export default connect(mapStateToProps)(MacaronsBoxSection)
