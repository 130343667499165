import React, { useState, useCallback } from "react"
import { MoonLoader } from "react-spinners"
import { useSwipeable } from "react-swipeable"
import styled from "styled-components"
import breakPoints from "../../APIs/devices"

export const Container = styled.div`
  position: relative;
  display: ${({ display }) => display};
  width: 70%;
  padding: 0;
  margin: 0 auto;
  justify-content: center;
  @media only screen and (min-width: ${breakPoints.lg}) {
    width: 90%;
    padding-top: 100%;
  }
`

const LoadingContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media only screen and (min-width: ${breakPoints.lg}) {
    align-items: flex-start;
    padding-top: 150px;
  }
`

const ImageSelectorContainer = styled.div`
  display: flex;
  position: absolute;
  margin: 0 auto;
  bottom: 2rem;
`

const ImageSelector = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 0.5rem;
  border: 2px solid ${({ selected }) => (selected ? "gray" : "white")};
  border-radius: 24px;
  background-color: ${({ selected }) => (selected ? "gray" : "lightgray")};
  &:hover {
    cursor: pointer;
    border-color: ${({ selected }) => (selected ? "gray" : "lightgray")};
  }
`

const StyledImage = styled.img`
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 30px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    position: absolute !important;
  }
`

const ProductImageContainer = ({ images, imgLoaded, setImgLoaded, title }) => {
  const [selected, setSelected] = useState(images.length > 1 ? 1 : 0)

  const imageRef = useCallback(node => {
    if (node) {
      node.style.height = `${node.clientWidth}px`
    }
  })

  const swipeHandlers = useSwipeable({
    onSwipedRight: () =>
      setSelected(prevState => {
        if (prevState - 1 >= 0) {
          return prevState - 1
        } else {
          return prevState
        }
      }),
    onSwipedLeft: () =>
      setSelected(prevState => {
        if (images.length - 1 >= prevState + 1) {
          return prevState + 1
        } else {
          return prevState
        }
      }),
  })

  return (
    <>
      {!imgLoaded && (
        <LoadingContainer ref={imageRef}>
          <MoonLoader size={150} color="#693c5e" />
        </LoadingContainer>
      )}

      <Container display={imgLoaded ? "flex" : "none"} {...swipeHandlers}>
        <StyledImage
          style={{ margin: 0 }}
          ref={imageRef}
          src={images[selected].node.url}
          alt={`${title} product image`}
          onLoad={() => setImgLoaded(true)}
        />
        {images.length > 1 && (
          <ImageSelectorContainer>
            {images.map((image, i) => (
              <ImageSelector
                key={i}
                onClick={() => setSelected(i)}
                selected={selected === i}
              />
            ))}
          </ImageSelectorContainer>
        )}
      </Container>
    </>
  )
}

export default ProductImageContainer
