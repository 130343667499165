import React, { useState } from "react"
import { gql } from "@apollo/client"
import { connect } from "react-redux"
import styled from "styled-components"
import breakPoints from "../../../APIs/devices"
import client from "../../../lib/shopifyApollo"
import { FlexContainer, FlexItem } from "../../FlexGrid/FlexGrid"
import { navigate } from "gatsby"
import { setCurrentVariant } from "../../../actions/setProductPage"

const Root = styled.div`
  position: relative;
  width: 100%;
`

const VariantContainer = styled(FlexItem)`
  margin-bottom: 1rem;
  @media only screen and (min-width: ${breakPoints.lg}) {
    &:nth-child(odd) {
      padding-right: 1rem;
    }
    &:nth-child(even) {
      padding-left: 1rem;
    }
  }
`

const Title = styled.h4`
  margin-bottom: 10px;
`

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 30px;
    right: 15px;
    border: 6px solid transparent;
    border-color: var(--ruelo-dark-purple) transparent transparent transparent;
    pointer-events: none;
  }
`

const Select = styled.select`
  padding: 1rem 1.5rem;
  border: 1px solid var(--black);
  border-radius: 10px;
  appearance: none;
  width: 100%;
  ${({ $isloading }) =>
    $isloading &&
    `
    color: var(--grey);
    pointer-events: none;
  `}
`

const VARIANT_BY_OPTIONS = gql`
  query MyQuery($handle: String!, $selectedOptions: [SelectedOptionInput!]!) {
    product(handle: $handle) {
      id
      variantBySelectedOptions(selectedOptions: $selectedOptions) {
        price {
          amount
        }
        image {
          id
          url(transform: { maxHeight: 300 })
        }
        availableForSale
        id
        title
      }
    }
  }
`

const VariantsSelection = ({
  dispatch,
  hasVariants,
  initialOptionsState,
  product,
}) => {
  const [optionsState, setOptionsState] = useState(initialOptionsState)
  const [loading, setLoading] = useState(false)

  async function handleChange(name, value) {
    setLoading(true)
    const newState = { ...optionsState }
    newState[name] = value

    const result = await client.query({
      query: VARIANT_BY_OPTIONS,
      variables: {
        handle: product.handle,
        selectedOptions: Object.keys(newState).map(name => ({
          name,
          value: newState[name],
        })),
      },
    })

    if (result.data && !result.loading) {
      const {
        product: { variantBySelectedOptions },
      } = result.data

      dispatch(setCurrentVariant(variantBySelectedOptions))
      setOptionsState(newState)
      setLoading(false)
    } else if (!result.loading) {
      navigate("ruelo.com/error")
    }
  }

  return (
    <Root>
      <FlexContainer>
        {hasVariants &&
          product.options.map(option => {
            return (
              <VariantContainer xs={12} lg={6} key={option.name}>
                <Title>{option.name}</Title>
                <SelectWrapper>
                  <Select
                    $isloading={loading}
                    value={optionsState[option.name]}
                    onChange={({ target: { value } }) =>
                      handleChange(option.name, value)
                    }
                  >
                    {option.values.map(value => (
                      <option key={value}>{value}</option>
                    ))}
                  </Select>
                </SelectWrapper>
              </VariantContainer>
            )
          })}
      </FlexContainer>
    </Root>
  )
}

const mapStateToProps = ({ productPage: { currentProduct } }) => {
  let hasVariants = true
  //Check if there's "no variants"
  if (currentProduct?.variants?.edges.length === 1) {
    if (currentProduct.variants.edges[0].node.title === "Default Title") {
      hasVariants = false
    }
  }
  const defaultVariant =
    currentProduct?.variants?.edges.filter(
      variant => variant.node.availableForSale
    )[0] || null

  //Find the first available variant. If none are available, choose the first variant.
  const initialOptionsState =
    defaultVariant?.node.selectedOptions.reduce((acc, cv) => {
      acc[cv.name] = cv.value
      return acc
    }, {}) ||
    currentProduct.options.reduce((acc, option) => {
      acc[option.name] = option.values[0]
      return acc
    }, {})

  return { hasVariants, initialOptionsState, product: currentProduct }
}

export default connect(mapStateToProps)(VariantsSelection)
