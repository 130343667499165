import React, { useCallback, useEffect, useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import firebase from "gatsby-plugin-firebase"
import "firebase/database"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"

import {
  setCurrentProduct,
  setCurrentVariant,
} from "../../actions/setProductPage"
import { setPreorder } from "../../actions/setProductPage"
import ProductImageContainer from "./ProductImageContainer"
import ProductDetails from "./ProductDetails"
import { FlexContainer, FlexItem } from "../FlexGrid/FlexGrid"
import breakPoints from "../../APIs/devices"
import ProductPageBlob from "./ProductPageBlob"
import { Container as ImageContainer } from "./ProductImageContainer"
import NoImage from "../../images/no_image.png"
import SEO from "../seo"

import SuspenseLoading from "../SuspenseLoading"

const Root = styled.div`
  position: relative;
  padding-top: 0;
  padding-bottom: 1rem;
  width: 100%;
  min-height: 80vh;
  overflow: hidden;
  @media only screen and (min-width: ${breakPoints.lg}) {
    padding-top: var(--header-height);
    padding-bottom: 5%;
  }
`

const Container = styled(FlexContainer)`
  position: relative;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 3px;
  padding: 0;
  padding-top: 70px;
  margin-top: 0;
  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ color }) => `var(--ruelo-${color})`};
    opacity: 0.4;
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    background-color: transparent;
    padding-right: 20px;
    padding-left: 20px;
    &::before {
      display: none;
    }
  }
  @media only screen and (min-width: ${breakPoints.xl}) {
    margin-top: 4vh;
    padding-top: 30px;
    &::before {
      display: none;
    }
  }
`
const ImageWrapper = styled(FlexItem)`
  position: relative;
  @media only screen and (max-width: ${breakPoints.lg}) {
    padding: 0;
  }
`
const MobileBackgroundSlate = styled.div`
  display: initial;
  position: absolute;
  width: 100%;
  height: 55%;
  background-color: ${({ isloading }) => (isloading ? "var(--grey)" : "white")};
  border-radius: 30px 30px 0 0;
  bottom: -20%;
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: none;
  }
`

const DetailsWrapper = styled(FlexItem)`
  position: relative;
  padding: 0;
`

const Image = styled.img`
  position: relative;
  display: ${({ display }) => display};
  width: 90%;
  margin: auto;
  @media only screen and (min-width: ${breakPoints.lg}) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`

const GET_PRODUCT = gql`
  query getProduct($handle: String!) {
    product(handle: $handle) {
      id
      tags
      title
      productType
      handle
      metafield(namespace: "global", key: "preorder-restriction") {
        key
        value
      }
      metafields(
        identifiers: [
          { key: "unavailable-at", namespace: "global" }
          { key: "preorder", namespace: "global" }
          { key: "preorder-restriction", namespace: "global" }
          { key: "box-capacity", namespace: "global" }
        ]
      ) {
        id
        key
        value
        namespace
      }
      descriptionHtml
      images(first: 10) {
        edges {
          node {
            id
            url(transform: { maxHeight: 600, maxWidth: 600 })
          }
        }
      }
      options(first: 10) {
        id
        name
        values
      }
      variants(first: 10) {
        edges {
          node {
            price {
              amount
            }
            image {
              id
              url(transform: { maxHeight: 300 })
            }
            selectedOptions {
              name
              value
            }
            availableForSale
            id
            title
          }
        }
      }
    }
  }
`

const ProductPage = ({ productHandle = "" }) => {
  //Redux
  const dispatch = useDispatch()
  const { currentProduct: product, productPageBlob } = useSelector(
    state => state.productPage
  )

  const { error, data } = useQuery(GET_PRODUCT, {
    variables: { handle: productHandle },
  })

  const [imgLoaded, setImgLoaded] = useState(false)

  const imageRef = useCallback(node => {
    if (node) {
      node.style.height = `${node.clientWidth}px`
    }
  })

  useEffect(() => {
    if (data) {
      //Set the product and focus variant.
      const { product: product } = data

      if (product === null) {
        navigate("404")
      } else {
        //Find the first availble variant.
        const firstAvailable = product.variants.edges.find(
          edge => edge.node.availableForSale
        )
        const firstVariant =
          firstAvailable?.node || product.variants.edges[0].node

        dispatch(setCurrentProduct(product))
        dispatch(setCurrentVariant(firstVariant))
      }
    }
  }, [data, dispatch])

  useEffect(() => {
    //On component mount.
    if (product) {
      firebase
        .database()
        .ref("products preorder/" + product.id)
        .once("value")
        .then(snapshot => {
          dispatch(setPreorder(snapshot.val()))
        })
        .catch(err => window.alert(err.message))
    }
  }, [dispatch, product])

  if (error) {
    console.error(`Error occured at product page: ${error}`)
    navigate("/error")
    return null
  }

  if (product) {
    return (
      <Root>
        <SEO
          title={`${product.title}`}
          keywords={["Ruelo Patisserie", product.title, product.productType]}
        />
        <ProductPageBlob />
        <Container margin={6} color={productPageBlob}>
          <ImageWrapper xs={12} lg={5}>
            <MobileBackgroundSlate />

            {product.images.edges.length > 0 ? (
              <ProductImageContainer
                title={product.title}
                images={product.images.edges}
                imgLoaded={imgLoaded}
                setImgLoaded={setImgLoaded}
              />
            ) : (
              <ImageContainer ref={imageRef}>
                <Image
                  display={imgLoaded ? "block" : "none"}
                  src={NoImage}
                  alt="no-image-available"
                  onLoad={() => setImgLoaded(true)}
                />
              </ImageContainer>
            )}
          </ImageWrapper>
          <DetailsWrapper xs={12} lg={7}>
            <ProductDetails />
          </DetailsWrapper>
        </Container>
      </Root>
    )
  }

  return <SuspenseLoading />
}

ProductPage.propTypes = {
  productHandle: PropTypes.string.isRequired,
}

export default React.memo(ProductPage)
