import React from "react"
import styled from "styled-components"

import small from "../../../images/6servings.svg"
import medium from "../../../images/8servings.svg"
import large from "../../../images/10servings.svg"
import square6 from "../../../images/square_6_servings.svg"
import square12 from "../../../images/square_12_servings.svg"

import { FlexContainer, FlexItem } from "../../FlexGrid/FlexGrid"
import breakPoints from "../../../APIs/devices"
import { connect } from "react-redux"
import { setCurrentVariant } from "../../../actions/setProductPage"
import VariantsSelection from "../GeneralSection/VariantsSelection"

const Container = styled(FlexContainer)`
  align-items: flex-end;
  border: 1px solid var(--grey);
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
`

export const UnavailableCurtain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  &:hover {
    ${({ hover }) => hover && `cursor: pointer`};
  }
  &:after {
    content: "SOLD OUT";
    white-space: pre;
    font-family: Sweet Sans Pro;
    color: white;
    font-size: 1.4rem;
  }
`

const SizeWrapper = styled(FlexItem)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${({ color }) => color};
  ${({ available }) => available}
  &:hover {
    cursor: pointer;
  }
  padding: 1rem;
  @media only screen and (min-width: ${breakPoints.lg}) {
    flex-direction: column;
    justify-content: center;
    padding: 20px 40px 20px 40px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 40%;
  @media only screen and (min-width: ${breakPoints.lg}) {
    width: 100%;
    max-width: 150px;
    margin-bottom: 1rem;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`

const SizeImage = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  margin-bottom: 0;
`

const SizeInfoWrapper = styled.div`
  float: left;
  text-align: left;
  margin-left: 2rem;
  @media only screen and (min-width: ${breakPoints.lg}) {
    float: initial;
    text-align: center;
    margin: 1rem 0px 0px;
  }
`

const SizePrice = styled.p`
  font-size: 1rem;
  margin-bottom: 0;
`

const SizeTitle = styled.p`
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 0.5rem;
`

const defaultServings = {
  '5"': 6,
  '7"': 8,
  '9"': 10,
  '3.5" x 7"': 6,
  '7" x 7"': 12,
}

const images = [small, medium, large]
const rectImgs = [square6, square12]

const SizeSection = ({
  dispatch,
  cakeType,
  currentVariant,
  variants: { edges: variants },
}) => {
  if (cakeType === "Options") {
    return <VariantsSelection />
  } else {
    return (
      <>
        <Container>
          {variants.map((variant, index) => {
            const {
              title,
              availableForSale,
              price: { amount },
            } = variant.node

            return (
              <SizeWrapper
                available={availableForSale ? "" : "pointer-events: none;"}
                color={variant.node === currentVariant ? "var(--grey)" : ""}
                key={index}
                lg={variants[2] ? 4 : 6}
                onClick={() => dispatch(setCurrentVariant(variant.node))}
                xs={12}
              >
                <ImageContainer>
                  <ImageWrapper>
                    <SizeImage
                      src={
                        cakeType === "Rectangular"
                          ? rectImgs[index]
                          : images[index]
                      }
                      alt={`servings: ${defaultServings[title]}`}
                    />
                  </ImageWrapper>
                </ImageContainer>
                <SizeInfoWrapper>
                  <SizeTitle>{title}</SizeTitle>
                  <SizePrice>{`$${Number(amount).toFixed(2)}`}</SizePrice>
                </SizeInfoWrapper>
                {!availableForSale && <UnavailableCurtain />}
              </SizeWrapper>
            )
          })}
        </Container>
        <i style={{ fontSize: "0.85rem" }}>
          *The number of slices indicates the number of recommended servings.
        </i>
      </>
    )
  }
}

const mapStateToProps = ({
  productPage: { currentProduct, currentVariant },
}) => {
  let cakeType
  if (currentProduct) {
    if (currentProduct.tags.includes("Rectangular")) {
      cakeType = "Rectangular"
    } else if (currentProduct.tags.includes("Circular")) {
      cakeType = "Circular"
    } else {
      cakeType = "Options"
    }
  }
  return { cakeType, currentVariant }
}
export default connect(mapStateToProps)(SizeSection)
