import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { animated } from "react-spring"
import { useSelector, useDispatch } from "react-redux"

import { FlexContainer, FlexItem } from "../../FlexGrid/FlexGrid"
import MacaronSlide from "./MacaronSlide"
import { cancelledAndResetSelection } from "../../../actions/macaronPageActions"
import breakPoints from "../../../APIs/devices"

const Root = styled.div`
  position: fixed;
  top: 0;
  z-index: var(--macarons-modal-zIndex);
  width: 100%;
  height: 100%;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
`

const Container = styled(animated(FlexContainer))`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1900px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 0;
  right: 0;
  padding: 3vh 20px 3vh 20px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    width: 90%;
    height: 90%;
    border-radius: 30px;
    padding: 5vh 7vw 5vh 7vw;
  }
`

const Wrapper = styled(FlexItem)`
  position: relative;
  margin: 0 auto;
  height: 90%;
  flex-wrap: wrap;
  flex-flow: column;
  @media only screen and (min-width: ${breakPoints.lg}) {
    height: 85%;
  }
`

const Header = styled.h1`
  position: relative;
  font-size: 1.7rem;
  margin-bottom: 4vh;
  flex: 0 1 auto;
  @media only screen and (min-width: ${breakPoints.md}) {
    font-size: 2.25rem;
  }
`

const ButtonWrapper = styled(FlexItem)`
  justify-content: space-evenly;
  align-items: center;
  @media only screen and (min-width: ${breakPoints.md}) {
    justify-content: flex-end;
    align-items: flex-end;
  }
`

const Button = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Sweet Sans Pro;
  width: 120px;
  height: 35px;
  border-radius: 8px;
  border: none;
  @media only screen and (min-width: 350px) {
    width: 140px;
    height: 40px;
  }
  @media only screen and (min-width: ${breakPoints.md}) {
    margin-right: 20px;
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    width: 15vw;
    height: 4vw;
    margin-left: 40px;
    border-radius: 15px;
  }
`

const CloseButton = styled(Button)`
  background-color: white;
  border: 3px solid var(--ruelo-dark-purple);
  color: var(--ruelo-dark-purple);
  &:hover {
    color: var(--black);
    cursor: pointer;
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    border: 5px solid var(--ruelo-dark-purple);
  }
`
const FinishButton = styled(Button)`
  background-color: var(--ruelo-dark-purple);
  color: white;
  &:hover {
    color: var(--black);
    cursor: pointer;
  }
`

const SelectionModal = ({ closePortal, style }) => {
  const [title, setTitle] = useState("")

  //Redux
  const dispatch = useDispatch()
  const { boxCap, selection } = useSelector(state => state.macaronPage)

  //Change the title depending on the amount of choices left.
  useEffect(() => {
    const difference = boxCap - selection.length
    if (difference === boxCap) {
      setTitle(`Pick your flavours!`)
    } else if (difference > 0) {
      setTitle(`Choose ${difference} more!`)
    } else {
      setTitle(`Done!`)
    }
  }, [boxCap, selection.length])

  return (
    <Root>
      <Background onClick={closePortal} />
      <Container style={style}>
        <Wrapper container xs={12}>
          <Header>{title}</Header>
          <MacaronSlide />
        </Wrapper>
        <ButtonWrapper container xs={12}>
          <CloseButton
            onClick={() => {
              closePortal()
              dispatch(cancelledAndResetSelection())
            }}
          >
            cancel
          </CloseButton>
          <FinishButton onClick={closePortal}>finish</FinishButton>
        </ButtonWrapper>
      </Container>
    </Root>
  )
}

export default React.memo(SelectionModal)
