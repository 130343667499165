import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import DOMPurify from "dompurify"

//date-fns
import format from "date-fns/format"
import addDays from "date-fns/addDays"

import breakPoints from "../../APIs/devices"
import { addProductToCart } from "../../actions/setCart"
import CakesSection from "./Cakes/CakesSection"
import MacaronsBoxSection from "./MacaronsBox/MacaronsBoxSection"
import { addToCartPopupOn } from "../../actions/toggleAddToCartPopup"
import { toggleNotCompleteWarning } from "../../actions/macaronPageActions"
import { FlexContainer } from "../FlexGrid/FlexGrid"
import GeneralSection from "./GeneralSection/GeneralSection"
import GeneralSectionNoVariants from "./GeneralSection/GeneralSectionNoVariants"
import determineEarliestDay from "../../APIs/determineEarliestDay"

const Container = styled.div`
  position: relative;
  text-align: left;
  background-color: white;
  padding: 0 20px 0 20px;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  overflow: hidden;
  width: 100%;
  @media only screen and (min-width: ${breakPoints.lg}) {
    padding: 40px 60px 40px 60px;
    border-radius: 30px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    margin-left: 3vw;
    min-height: 40vh;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const ProductName = styled.h1`
  position: relative;
  text-align: left;
  color: var(--black);
  font-size: 2rem;
  @media only screen and (min-width: ${breakPoints.sm}) {
    font-size: 2.25rem;
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    font-size: 3rem;
  }
  margin-bottom: 2rem;
`

const DescriptionContainer = styled.div`
  margin-bottom: 2rem;
  & li {
    margin-bottom: 0.5rem;
  }
`

const CheckoutWrapper = styled(FlexContainer)`
  width: 100%;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  @media only screen and (min-width: ${breakPoints.lg}) {
    margin-bottom: 4rem;
    flex-direction: row;
  }
`

const ProductPrice = styled.h1`
  position: relative;
  text-align: left;
  margin-bottom: 10px;
  width: 100%;
  color: var(--black);
  font-size: 2rem;
  @media only screen and (min-width: ${breakPoints.lg}) {
    font-size: 2.25rem;
  }
`

const AddProductButton = styled.button`
  position: relative;
  font-family: Sweet Sans Pro;
  background-color: ${({ bgcolor, available }) =>
    available === "true" ? bgcolor : "var(--grey)"};
  border: none;
  border-radius: 15px;
  color: white;
  padding: 16px 0 16px 0;
  max-width: 230px;
  flex: 1 1 auto;
  margin-left: 3rem;
  pointer-events: ${({ available }) => (available ? "" : "none")};
  ${({ available }) =>
    available === "true" &&
    `&:hover {
    color: black;
    cursor: pointer;
  }`}
  &:active {
    transform: ${({ transform, available }) => available && transform};
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    flex: initial;
    margin-left: 2rem;
    width: 230px;
  }
`

const LocationContainer = styled.div`
  margin-bottom: 2rem;
`

const LeftContainer = styled.div``

const RightContainer = styled.div`
  display: flex;
`

const DaysLater = styled.div`
  white-space: nowrap;
  margin-right: 3px;
`

const PickupTime = styled(DaysLater)`
  margin-right: 0;
`

const ProductDetails = ({
  boxCap,
  currentVariant,
  description,
  dispatch,
  locations,
  locationRestrictions,
  message,
  product: { productType, variants, title, metafield },
  selection,
  selectedMacarons,
}) => {
  const [customSection, setCustomSection] = useState("")
  const [pickupDay, setPickupDay] = useState(
    format(addDays(new Date(), 1), "MMM do")
  )
  const [pickupTime, setPickupTime] = useState("")
  const [addToCartProps, setAddToCartProps] = useState({
    bgcolor: "var(--ruelo-dark-purple)",
    transform: "scale(0.97)",
  })
  const [daysOfWeek, setDaysOfWeek] = useState("")

  //Customize based on the the type of product
  useEffect(() => {
    //Cakes
    if (productType === "Cakes") {
      setCustomSection(<CakesSection variants={variants} />)
    } else if (productType === "Macaron Box") {
      setCustomSection(<MacaronsBoxSection />)
    } else if (productType === "Mini Cakes" || productType === "Macarons") {
      setCustomSection(<GeneralSectionNoVariants />)
    } else {
      setCustomSection(<GeneralSection />)
    }
  }, [productType, variants])

  const handleAddProductToCart = () => {
    const customAttributes = {}

    //Check if macaron page & complete
    if (boxCap > 0) {
      if (selection.length < boxCap) {
        dispatch(toggleNotCompleteWarning(true))
        setTimeout(() => dispatch(toggleNotCompleteWarning(false)), 1500)
        return
      } else {
        customAttributes.selection = Object.keys(selectedMacarons)
          .map(
            macaron =>
              `${selectedMacarons[macaron].title} X ${selectedMacarons[macaron].amount}`
          )
          .join(`\n`)
      }
    }

    //For cake messages
    if (message !== "") {
      customAttributes.message = message
    }

    dispatch(
      addProductToCart({
        variantId: currentVariant.id,
        customAttributes,
      })
    )
    dispatch(addToCartPopupOn())
  }

  const renderLocations = () => {
    if (locationRestrictions) {
      if (Object.keys(locations).length === locationRestrictions.length) {
        return (
          <LocationContainer>
            <div>
              <u>Available Locations:</u>
            </div>
            All
          </LocationContainer>
        )
      }

      return (
        <LocationContainer>
          <LeftContainer>
            <u>Available Locations:</u>
          </LeftContainer>
          <RightContainer>
            {` ${
              locationRestrictions.length > 0
                ? locationRestrictions.map(location => location).join(", ")
                : "Currently Unavailable"
            }`}
          </RightContainer>
        </LocationContainer>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    if (metafield !== null) {
      const value = JSON.parse(metafield.value)

      const { days, daysAvailable } = value

      const earliest = determineEarliestDay(value)

      const before =
        value.before &&
        `${value.before.hour}: ${value.before.minute} ${value.before.am_pm}`

      const after =
        value.after &&
        `${value.after.hour}: ${value.after.minute} ${value.after.am_pm}`

      if (days) {
        setPickupDay(format(earliest, "MMM do"))
      }

      if (before && after) {
        setPickupTime(`| between ${after} - ${before}`)
      } else if (before) {
        setPickupTime(`| before ${before}`)
      } else if (after) {
        setPickupTime(`| after ${after}`)
      }

      if (daysAvailable) {
        const filteredDays = Object.keys(daysAvailable).filter(
          key => daysAvailable[key]
        )

        if (
          filteredDays.length === 2 &&
          daysAvailable["Sun"] &&
          daysAvailable["Sat"]
        ) {
          setDaysOfWeek(" | Only on weekends")
        } else if (
          filteredDays.length === 5 &&
          daysAvailable["Mon"] &&
          daysAvailable["Tue"] &&
          daysAvailable["Wed"] &&
          daysAvailable["Thu"] &&
          daysAvailable["Fri"]
        ) {
          setDaysOfWeek(" | Only on weekdays")
        } else if (filteredDays.length === 7) {
          setDaysOfWeek("")
        } else if (filteredDays.length > 0) {
          setDaysOfWeek(" | Only on " + filteredDays.join(", "))
        }
      }
    }
  }, [metafield])

  useEffect(() => {
    if (boxCap > 0) {
      const inComplete = boxCap > 0 && selection.length < boxCap
      setAddToCartProps({
        bgcolor: inComplete ? "darkgray" : "var(--ruelo-dark-purple)",
        transform: inComplete ? "" : "scale(0.97)",
      })
    }
  }, [boxCap, selection])

  if (!productType || currentVariant === null) {
    return null
  }

  return (
    <Container>
      <ProductName>{title}</ProductName>
      {description !== null && (
        <DescriptionContainer
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {renderLocations()}
      <LocationContainer>
        <u>Earliest pick-up:</u>
        <RightContainer>
          {" "}
          <DaysLater>{pickupDay}</DaysLater>
          <PickupTime>{pickupTime}</PickupTime>
          <PickupTime>{daysOfWeek}</PickupTime>
        </RightContainer>
        <div>
          <i>
            Need an earlier time? <a href="tel:9057621500"> Give us a call</a>
          </i>
        </div>
      </LocationContainer>

      {customSection}

      <CheckoutWrapper>
        <div>
          <ProductPrice>{`$${Number(currentVariant.price.amount).toFixed(
            2
          )}`}</ProductPrice>
        </div>
        <AddProductButton
          available={currentVariant.availableForSale ? "true" : "false"}
          onClick={() =>
            currentVariant.availableForSale && handleAddProductToCart()
          }
          {...addToCartProps}
        >
          {`${currentVariant.availableForSale ? "add to cart" : "unavailable"}`}
        </AddProductButton>
      </CheckoutWrapper>
    </Container>
  )
}

const mapStateToProps = ({
  productPage: { currentProduct: product, currentVariant, message },
  macaronPage: { selectedMacarons, boxCap, selection },
  stores: { locations },
}) => {
  let locationRestrictions = []

  const unavailableAt = product?.metafields?.filter(
    field => field?.key === "unavailable-at"
  )

  //If there are restrictions
  if (locations && unavailableAt) {
    if (unavailableAt.length > 0 && Object.keys(locations)?.length > 0) {
      const restrictions = JSON.parse(unavailableAt[0].value)

      locationRestrictions = Object.keys(locations).reduce((acc, id) => {
        if (!restrictions.includes(id)) {
          acc.push(locations[id].name)
        }
        return acc
      }, [])
    } else {
      locationRestrictions = Object.keys(locations).map(
        location => locations[location].name
      )
    }
  }

  //If descriptions
  let description = null
  if (product && product.descriptionHtml) {
    description = DOMPurify.sanitize(product.descriptionHtml)
  }

  return {
    boxCap,
    currentVariant,
    description,
    locationRestrictions,
    locations,
    message,
    product,
    selectedMacarons,
    selection,
  }
}

export default connect(mapStateToProps)(ProductDetails)
