import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Portal } from "react-portal"
import { useSelector, useDispatch } from "react-redux"
import { useTransition } from "react-spring"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"

import { FlexContainer, FlexItem } from "../../FlexGrid/FlexGrid"
import SelectionModal from "./SelectionModal"
import {
  setMacarons,
  setBoxCapacity,
  removeAllSelectedMacarons,
  setPreviousSelectedMacarons,
} from "../../../actions/macaronPageActions"
import Macaron from "./Macaron"
import breakPoints from "../../../APIs/devices"
import MacaronEmpty from "./MacaronEmpty"
import lockBody from "../../../APIs/lockBody"
import { navigate } from "gatsby"

const Container = styled(FlexContainer)`
  position: relative;
  margin-top: 30px;
  justify-content: center;
`
const Header = styled.h3`
  position: relative;
  text-align: center;
`

const SelectionRow = styled(FlexItem)`
  position: relative;
  max-width: 500px;
  justify-content: center;
`

const BoxContainer = styled(FlexItem)`
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const MacaronText = styled.div`
  font-family: Sweet Sans Pro;
  text-align: center;
  min-height: 80px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    margin-top: 10px;
  }
`

const MacaronsSelection = () => {
  const [isPortalOpen, setPortal] = useState(false)
  const [rowNum, setRowNum] = useState(0)

  const transitions = useTransition(isPortalOpen, null, {
    from: { transform: "translate3d(0%, -156%, 0)" },
    enter: { transform: "translate3d(0%, -50%, 0)" },
    leave: { transform: "translate3d(0%, -156%, 0)" },
  })

  const GET_MACARONS = gql`
    {
      products(first: 100, query: "product_type:Macarons") {
        edges {
          node {
            id
            handle
            title
            metafields(
              identifiers: [
                { key: "box-capacity", namespace: "global" }
                { key: "mac-filling", namespace: "global" }
                { key: "mac-top", namespace: "global" }
                { key: "mac-bottom", namespace: "global" }
              ]
            ) {
              id
              key
              namespace
              value
            }
          }
        }
      }
    }
  `

  //Redux
  const dispatch = useDispatch()
  const { currentProduct: product } = useSelector(state => state.productPage)
  const { boxCap, selection, notCompleteWarning } = useSelector(
    state => state.macaronPage
  )

  const { loading, error, data } = useQuery(GET_MACARONS)

  useEffect(() => {
    lockBody(isPortalOpen)
  }, [isPortalOpen])

  //Filter for the box-capacity metafield.
  useEffect(() => {
    const filter = product.metafields.filter(field => {
      return field?.key === "box-capacity"
    })

    const cap = Number(filter[0].value)
    dispatch(setBoxCapacity(cap))
    setRowNum(Math.ceil(cap / 3))
    return () => {
      dispatch(setBoxCapacity(0))
      dispatch(removeAllSelectedMacarons())
    }
  }, [dispatch, product])

  useEffect(() => {
    data && dispatch(setMacarons(data.products.edges))
  }, [dispatch, data])

  //Everytime the portal opens, save a previous state in case modal is cancelled.
  useEffect(() => {
    isPortalOpen && dispatch(setPreviousSelectedMacarons())
  }, [isPortalOpen, dispatch])

  if (error) {
    console.error(`Error occured at macarons selection: ${error}`)
    navigate("/error")
    return null
  }

  return (
    <Container>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Portal
              key={key}
              node={document && document.getElementById("view-port")}
            >
              <SelectionModal
                style={props}
                closePortal={() => setPortal(false)}
              />
            </Portal>
          )
      )}

      <FlexItem xs={12}>
        <Header>select your macarons</Header>
      </FlexItem>
      {Array.from({ length: rowNum }).map((row, i) => (
        <SelectionRow
          container
          xs={12}
          key={i}
          margin={6}
          onClick={() => setPortal(true)}
        >
          <BoxContainer container xs={4}>
            {selection[i * 3] ? (
              <>
                <Macaron metafields={selection[i * 3].node.metafields} />
                <MacaronText>
                  {selection[i * 3].node.title.toLowerCase()}
                </MacaronText>
              </>
            ) : (
              <>
                <MacaronEmpty color={notCompleteWarning && "var(--delete)"} />
                <MacaronText>none</MacaronText>
              </>
            )}
          </BoxContainer>

          <BoxContainer container xs={4}>
            {2 + i * 3 <= boxCap ? (
              selection[1 + i * 3] ? (
                <>
                  <Macaron metafields={selection[1 + i * 3].node.metafields} />
                  <MacaronText>
                    {selection[1 + i * 3].node.title.toLowerCase()}
                  </MacaronText>
                </>
              ) : (
                <>
                  <MacaronEmpty color={notCompleteWarning && "var(--delete)"} />
                  <MacaronText>none</MacaronText>
                </>
              )
            ) : null}
          </BoxContainer>

          <BoxContainer container xs={4}>
            {3 + i * 3 <= boxCap ? (
              selection[2 + i * 3] ? (
                <>
                  <Macaron metafields={selection[2 + i * 3].node.metafields} />
                  <MacaronText>
                    {selection[2 + i * 3].node.title.toLowerCase()}
                  </MacaronText>
                </>
              ) : (
                <>
                  <MacaronEmpty color={notCompleteWarning && "var(--delete)"} />
                  <MacaronText>none</MacaronText>
                </>
              )
            ) : null}
          </BoxContainer>
        </SelectionRow>
      ))}
    </Container>
  )
}

export default React.memo(MacaronsSelection)
