import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { FlexContainer, FlexItem } from "../../FlexGrid/FlexGrid"
import { ToggleBar, ToggleItem } from "../ToggleBar"
import SizeSection from "./SizeSection"
import ProductDescriptionToggle from "../ProductDescriptionToggle"
import ProductDescription from "../ProductDescription"
import { useSelector, useDispatch } from "react-redux"
import { setCakeMessage } from "../../../actions/setProductPage"
import VariantsSelection from "../GeneralSection/VariantsSelection"

const Container = styled(FlexContainer)`
  position: relative;
`

const MessageContainer = styled.div`
  width: 100%;
  margin: 2rem auto 0;
  position: relative;
`

const MessageWrapper = styled.div`
  position: relative;
  &:after {
    content: "${({ warning }) => warning}";
    position: absolute;
    width: 100%;
    height: 20px;
    text-align: left;
    bottom:-1rem;
    font-size: 0.85rem;
    left: 0;
    color: var(--delete);
  }
`

const MessageBox = styled.textarea`
  position: relative;
  width: 100%;
  min-height: 100px;
  max-height: inherit;
  resize: vertical;
  padding: 12px 20px;
  margin: 8px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`

const CakesSection = ({ variants }) => {
  //Redux
  const dispatch = useDispatch()
  const { message } = useSelector(state => state.productPage)

  const [sizes, setSizes] = useState([])
  const [toggle, setToggle] = useState("Sizes")
  const [warning, setWarning] = useState("")

  useEffect(() => {
    //Format variants
    setSizes(variants.edges.map(edge => edge.node.title))
  }, [variants])

  return (
    <Container>
      <SizeSection variants={variants} sizes={sizes} />
      <MessageContainer>
        <u>Add a cake message</u>
        <MessageWrapper warning={warning}>
          <MessageBox
            placeholder="Write your message here..."
            value={message}
            onChange={e => {
              if (e.target.value.indexOf(",") > -1) {
                setWarning("Commas are not allowed.")
              } else if (e.target.value.length <= 30) {
                setWarning("")
                dispatch(setCakeMessage(e.target.value))
              } else {
                setWarning("Max character limit reached.")
              }
            }}
          />
        </MessageWrapper>
      </MessageContainer>
    </Container>
  )
}

export default React.memo(CakesSection)
