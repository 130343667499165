import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { ToggleBar, ToggleItem } from "../ToggleBar"
import ProductDescriptionToggle from "../ProductDescriptionToggle"
import ProductDescription from "../ProductDescription"
import VariantsSelection from "./VariantsSelection"

const GeneralSection = ({ hasDescription, hasVariants }) => {
  const [toggle, setToggle] = useState("Options")

  useEffect(() => {
    if (hasDescription && hasVariants) {
      setToggle("Options")
    } else if (hasVariants) {
      setToggle("Options")
    } else if (hasDescription) {
      setToggle("Description")
    } else {
      setToggle(null)
    }
  }, [hasDescription, hasVariants])

  return <VariantsSelection />
}

const mapStateToProps = ({ productPage: { currentProduct } }) => {
  let hasVariants = false
  let hasDescription = false

  if (currentProduct?.descriptionHtml !== "") {
    hasDescription = true
  }

  if (currentProduct.variants.edges.length > 1) {
    hasVariants = true
  }

  return {
    hasDescription,
    hasVariants,
    product: currentProduct,
  }
}

export default connect(mapStateToProps)(GeneralSection)
