import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import breakPoints from "../../../APIs/devices"

const Container = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const SVG = styled.svg`
  width: ${({ width }) => (width === "" ? "88px" : width)};
  height: ${({ height }) => (height === "" ? "71.2px" : height)};
  @media only screen and (min-width: ${breakPoints.md}) {
    width: ${({ width }) => (width === "" ? "99px" : width)};
    height: ${({ height }) => (height === "" ? "80.1px" : height)};
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    width: ${({ width }) => (width === "" ? "110px" : width)};
    height: ${({ height }) => (height === "" ? "89px" : height)};
  }
`

const Macaron = ({ width: newWidth, height: newHeight, metafields = {} }) => {
  const [fields, setFields] = useState({})

  useEffect(() => {
    const reduceFields = metafields.reduce((accumulator, field) => {
      if (field) {
        return Object.assign(accumulator, { [field.key]: field.value })
      }
      return accumulator
    }, {})
    setFields(reduceFields)
  }, [metafields])

  return (
    <Container>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={newWidth ? `${newWidth}` : ``}
        height={newHeight ? `${newHeight}` : ``}
        viewBox="0 0 110 89"
      >
        <g
          id="Group_129"
          data-name="Group 129"
          transform="translate(1956 -13293)"
        >
          <path
            id="Rectangle_130"
            data-name="Rectangle 130"
            d="M28.333,0H81.667A28.333,28.333,0,0,1,110,28.333v0A5.667,5.667,0,0,1,104.333,34H5.667A5.667,5.667,0,0,1,0,28.333v0A28.333,28.333,0,0,1,28.333,0Z"
            transform="translate(-1956 13293)"
            fill={fields["mac-top"] ? fields["mac-top"] : "#e0c6ad"}
          />
          <path
            id="Rectangle_133"
            data-name="Rectangle 133"
            d="M5.5,0h99A5.5,5.5,0,0,1,110,5.5v0A27.5,27.5,0,0,1,82.5,33h-55A27.5,27.5,0,0,1,0,5.5v0A5.5,5.5,0,0,1,5.5,0Z"
            transform="translate(-1956 13349)"
            fill={fields["mac-bottom"] ? fields["mac-bottom"] : "#e0c6ad"}
          />
          <rect
            id="Rectangle_132"
            data-name="Rectangle 132"
            width="110"
            height="16"
            rx="8"
            transform="translate(-1956 13330)"
            fill={fields["mac-filling"] ? fields["mac-filling"] : "#98663f"}
          />
        </g>
      </SVG>
    </Container>
  )
}

Macaron.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  metafields: PropTypes.array.isRequired,
}

export default React.memo(Macaron)
