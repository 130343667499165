export const addToCartPopupOn = () => dispatch => {
  dispatch({
    type: "ADD_TO_CART_POPUP_ON",
  })

  return setTimeout(() => dispatch(addToCartPopupOff()), 1500)
}
export const addToCartPopupOff = () => ({
  type: "ADD_TO_CART_POPUP_OFF",
})
