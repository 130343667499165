import React, { useState, useEffect } from "react"
import styled from "styled-components"
import useWindowDimensions from "../../APIs/window_dimensions"

import ProductPageBlobP from "../../images/product_page_blob_purple.svg"
import ProductPageBlobG from "../../images/product_page_blob_grey.svg"
import ProductPageBlobB from "../../images/product_page_blob_beige.svg"
import { useSelector } from "react-redux"
import breakPoints from "../../APIs/devices"

const BackgroundBlobWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  display: none;
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: block;
  }
`

const BackgroundBlob = styled.div`
  position: absolute;
  right: 0;
  top: -${({ width }) => 400 * (400 / (0.25 * width))}px; /*Used to calculate the amount to move the blob up by*/
  height: 2000px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto;
`

const ProductPageBlob = () => {
  const [blobColor, setBlobColor] = useState(ProductPageBlobP)

  const { width } = useWindowDimensions()
  const { productPageBlob: color } = useSelector(state => state.productPage)

  useEffect(() => {
    switch (color) {
      case "grey": {
        setBlobColor(ProductPageBlobG)
        break
      }
      case "beige": {
        setBlobColor(ProductPageBlobB)
        break
      }
      default:
        setBlobColor(ProductPageBlobP)
    }
  }, [color])

  return (
    <BackgroundBlobWrapper>
      <BackgroundBlob
        width={width}
        style={{
          backgroundImage: `url(${blobColor})`,
          opacity: 0.4,
        }}
      />
    </BackgroundBlobWrapper>
  )
}

export default ProductPageBlob
